import fishData from "./fishData";
import meatData from "./meatData";
import eggsData from "./eggsData";
import grainsData from "./grainsData";
import dairyData from "./dairyData";
import fruitvegData from "./fruitvegData";
import legumesData from "./legumesData";
import shellfishData from "./shellfishData";

import bones from "../assets/black/bones_black.svg";
import brain from "../assets/black/brain_black.svg";
import digestion from "../assets/black/digestion_black.svg";
import energy from "../assets/black/energy_black.svg";
import eyes from "../assets/black/eyes_black.svg";
import hair from "../assets/black/hair_black.svg";
import heart from "../assets/black/heart_black.svg";
import immunity from "../assets/black/immunity_black.svg";
import liver from "../assets/black/liver_black.svg";
import muscles from "../assets/black/muscles_black.svg";

const dataBody = [
  [
    { image: { energy }, title: "energy" },
    [
      { data: grainsData, title: "B Vitamins" },
      { data: meatData, title: "Iron & Vitamin B12" },
      { data: fruitvegData, title: "Vitamin C & B Vitamins" },
      { data: legumesData, title: "Iron & Magnesium" }
    ]
  ],
  [
    {
      image: { brain },
      title: "Mental Performance"
    },
    [
      { data: grainsData, title: "B Vitamins" },
      { data: meatData, title: "Iron" },
      { data: fishData, title: "Iodine" },
      { data: legumesData, title: "Magnesium" }
    ]
  ],
  [
    { image: { immunity }, title: "immunity" },
    [
      { data: fruitvegData, title: "Vitamin C & A" },
      { data: fishData, title: "Vitamin D" },
      { data: dairyData, title: "Zinc" },
      { data: meatData, title: "Iron" }
    ]
  ],
  [
    { image: { heart }, title: "heart" },
    [
      { data: fishData, title: "Omega-3" },
      { data: meatData, title: "VITAMIN B12 & D" },
      { data: grainsData, title: "Magnesium" },
      { data: fruitvegData, title: "Vitamin K" }
    ]
  ],
  [
    { image: { bones }, title: "bones" },
    [
      { data: dairyData, title: "calcium" },
      { data: grainsData, title: "MAGNESIUM & MANGANESE" },
      { data: fishData, title: "vitamin d" },
      { data: fruitvegData, title: "vitamin k" }
    ]
  ],
  [
    { image: { muscles }, title: "muscles" },
    [
      { data: fishData, title: "protein" },
      { data: grainsData, title: "magnesium" },
      { data: fishData, title: "Vitamin D" },
      { data: dairyData, title: "Calcium" }
    ]
  ],
  [
    { image: { digestion }, title: "DIGESTION & METABOLISM" },
    [
      { data: dairyData, title: "CALCIUM" },
      { data: shellfishData, title: "zinc" },
      { data: grainsData, title: "CHROMIUM" },
      { data: fruitvegData, title: "folate" }
    ]
  ],
  [
    { image: { hair }, title: "Hair, skin, nails" },
    [
      { data: shellfishData, title: "ZINC & SELENIUM" },
      { data: meatData, title: "ZINC & SELENIUM" },
      { data: grainsData, title: "ZINC & Copper" },
      { data: fruitvegData, title: "Vitamin c & a" }
    ]
  ],
  [
    { image: { liver }, title: "liver" },
    [
      { data: meatData, title: "choline" },
      { data: eggsData, title: "choline" },
      { data: fishData, title: "choline" }
    ]
  ],
  [
    { image: { eyes }, title: "eyes" },
    [
      { data: meatData, title: "zinc" },
      { data: grainsData, title: "zinc" },
      { data: fishData, title: "omega-3" },
      { data: fruitvegData, title: "vitamin a & b2" }
    ]
  ]
];

export default dataBody;

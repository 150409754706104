import React, { useState, useEffect } from "react";

function PieAnimationChart(props) {
  const [strokeLength, setStrokeLength] = useState();

  useEffect(() => {
    setTimeout(() => {
      setStrokeLength(true);
    });
  });

  let radius = 30;
  // let progress = 30;
  let strokeWidth = 2;
  let dimension = 80;

  const circleRadius = Math.min(radius, 85);
  const circumference = 2 * 3.14 * circleRadius;
  const strokeLengt = strokeLength
    ? (circumference / 100) * `${props.progress}`
    : 0;

  return (
    <>
      <div className="pie-chart">
        <svg viewBox="0 0 80 80" width={dimension} height={dimension}>
          <circle
            className="radial-chart-total"
            stroke="rgba(0, 0, 0, .3)"
            strokeWidth={strokeWidth}
            fill="none"
            cx="40"
            cy="40"
            r={circleRadius}
          />
          <circle
            className="radial-chart-progress"
            stroke="#F99B20"
            opacity={props.opacity}
            strokeWidth={strokeWidth}
            strokeDasharray={`${strokeLengt},${circumference}`}
            strokeLinecap="round"
            fill="none"
            cx="40"
            cy="40"
            r={circleRadius}
          />
        </svg>
        <div
          style={{
            background: `url(${Object.values(
              props.image.img
            )}) center center no-repeat`
          }}
          className="icon-svg"
        />

        <span
          style={
            props.uppercase
              ? { textTransform: "uppercase" }
              : {
                textTransform: "capitalize",
                fontFamily: "Akzidenz-grotesk Light, sans-serif"
              }
          }
        >
          {props.text}
        </span>
      </div>
    </>
  );
}

export default PieAnimationChart;

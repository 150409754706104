import React from "react";

import PieAnimationChart from "./PieAnimationChart";
import wave from '../assets/wave-png.png';

function CardFood(props) {
  return (
    <div className="results__block">
      <div
        className="results__block--left"
        style={{
          backgroundColor: "#fff",
          color: "#000"
        }}
      >
        <img className="left-wave" src={wave} />

        <PieAnimationChart
          progress={props.data[0].data.progress}
          opacity={
            props.data[0].data.progress > 71
              ? "1"
              : props.data[0].data.progress < 71 &&
                props.data[0].data.progress > 40
                ? ".6"
                : ".3"
          }
          text={props.data[0].data.text}
          image={props.data[0].data}
          uppercase={true}
        />
        {/* <svg
          width="12"
          height="150"
          viewBox="0 0 12 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="wave-element-card wave-element-card-white"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.00019 150C1.00019 150 1.00019 146.458 1.00019 122.607C1.00019 98.7569 11.4199 95.0582 11.4199 76C11.4199 56.9418 1.00019 51.6806 1.00019 28C1.00019 4.31936 1.00019 0 1.00019 0H0.000190735L0.000190735 150H1.00019Z"
            fill="white"
          />
        </svg> */}
      </div>
      <div
        className="results__block--right"
        style={{
          backgroundColor: "#fff",
          color: "#000"
        }}
      >
        {props.data[1].map((data, i) => {
          return (
            <div className="icon-wrap" key={i}>
              <span>{data.title}</span>
              <div
                style={{
                  background: `url(${Object.values(
                    data.image
                  )}) center center no-repeat`,
                  width: "30px",
                  height: "30px"
                }}
              />
              <span
                style={{
                  width: " 100px",
                  textAlign: "center",
                  textTransform: "capitalize",
                  fontFamily: "Akzidenz-grotesk Light, sans-serif"
                }}
              >
                {data.iconDescr}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CardFood;

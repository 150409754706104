import React from "react";

function SwitcherBtn(props) {
  return (
    <>
      <div className="switcher">
        <button
          className="switcher__button switcher__button-food"
          onClick={props.handleBodyClick}
          style={
            props.foodActive === true
              ? {
                color: "#fff"
              }
              : { color: "#000" }
          }
        >
          Your body's needs
        </button>
        <button
          className="switcher__button switcher__button-body"
          onClick={props.handleFoodClick}
          style={
            props.foodActive === true
              ? { color: "#000" }
              : {
                color: "#fff"
              }
          }
        >
          Your diet's nutrients
        </button>

        <button
          className="switcher__move-btn"
          onClick={props.handleFoodClick}
          style={
            props.foodActive === true
              ? { transform: "translateX(-100%)" }
              : {
                transform: "translateX(0)"
              }
          }
        />
      </div>
      {/* <p className="switcher-subtitle">
        Your body’s needs, their essential nutrients and the best foods for
        them.
      </p> */}
    </>
  );
}

export default SwitcherBtn;

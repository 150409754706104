import fishData from "./fishData";
import meatData from "./meatData";
import eggsData from "./eggsData";
import grainsData from "./grainsData";
import dairyData from "./dairyData";
import fruitvegData from "./fruitvegData";
import legumesData from "./legumesData";
import shellfishData from "./shellfishData";
import meatsubstitutesData from "./meatsubstitutesData";
import fatsData from "./fatsData";

import bones from "../assets/black/bones_black.svg";
import brain from "../assets/black/brain_black.svg";
import digestion from "../assets/black/digestion_black.svg";
import energy from "../assets/black/energy_black.svg";
import eyes from "../assets/black/eyes_black.svg";
import hair from "../assets/black/hair_black.svg";
import heart from "../assets/black/heart_black.svg";
import immunity from "../assets/black/immunity_black.svg";
import liver from "../assets/black/liver_black.svg";

const dataFood = [
  [
    {
      data: meatData
    },
    [
      { image: { bones }, iconDescr: "bones", title: "Protein" },
      { image: { brain }, iconDescr: "Mental performance", title: "iron" },
      { image: { energy }, iconDescr: "energy", title: "vitamin b12" },
      { image: { digestion }, iconDescr: "digestion", title: "zinc" }
    ]
  ],
  [
    {
      data: fishData
    },
    [
      { image: { energy }, iconDescr: "energy", title: "Protein" },
      { image: { heart }, iconDescr: "heart", title: "omega-3" },
      { image: { immunity }, iconDescr: "immunity", title: "Vitamin d" },
      { image: { brain }, iconDescr: "Mental performance", title: "iodine" }
    ]
  ],
  [
    {
      data: fruitvegData
    },
    [
      { image: { immunity }, iconDescr: "immunity", title: "vitamin c" },
      { image: { energy }, iconDescr: "energy", title: "b vitamins" },
      { image: { heart }, iconDescr: "heart", title: "folate" },
      { image: { eyes }, iconDescr: "eyes", title: "Vitamin a" }
    ]
  ],
  [
    {
      data: grainsData
    },
    [
      { image: { bones }, iconDescr: "bones", title: "magnesium" },
      { image: { hair }, iconDescr: "hair, skin, nails", title: "Vitamin e" },
      { image: { energy }, iconDescr: "energy", title: "b Vitamins" },
      { image: { brain }, iconDescr: "Mental performance", title: "copper" }
    ]
  ],
  [
    {
      data: shellfishData
    },
    [
      { image: { energy }, iconDescr: "energy", title: "Protein" },
      { image: { hair }, iconDescr: "hair, skin, nails", title: "SELENIUM" },
      { image: { brain }, iconDescr: "Mental performance", title: "iodine" },
      { image: { heart }, iconDescr: "heart", title: "omega-3" }
    ]
  ],
  [
    {
      data: dairyData
    },
    [
      { image: { energy }, iconDescr: "energy", title: "Protein" },
      { image: { bones }, iconDescr: "bones", title: "calcium" },
      { image: { energy }, iconDescr: "energy", title: "b Vitamins" },
      { image: { heart }, iconDescr: "heart", title: "Vitamin b12" }
    ]
  ],
  [
    {
      data: eggsData
    },
    [
      { image: { energy }, iconDescr: "energy", title: "Protein" },
      { image: { liver }, iconDescr: "liver", title: "choline" },
      { image: { heart }, iconDescr: "heart", title: "Vitamin d" },
      { image: { energy }, iconDescr: "energy", title: "b Vitamins" }
    ]
  ],
  [
    {
      data: fatsData
    },
    [
      { image: { hair }, iconDescr: "hair, skin, nails", title: "vitamin e" },
      { image: { heart }, iconDescr: "heart", title: "omega-3" },
      { image: { immunity }, iconDescr: "immunity", title: "vitamin d" }
    ]
  ],
  [
    {
      data: meatsubstitutesData
    },
    [
      { image: { energy }, iconDescr: "energy", title: "Protein" },
      { image: { digestion }, iconDescr: "digestion", title: "Manganese" },
      { image: { hair }, iconDescr: "hair, skin, nails", title: "Copper" },
      { image: { brain }, iconDescr: "Mental performance", title: "iron" }
    ]
  ],
  [
    {
      data: legumesData
    },
    [
      { image: { energy }, iconDescr: "energy", title: "b vitamins" },
      { image: { heart }, iconDescr: "heart", title: "MAGNESIUM" },
      { image: { bones }, iconDescr: "bones", title: "protein" },
      { image: { immunity }, iconDescr: "immunity", title: "iron" }
    ]
  ]
];

export default dataFood;

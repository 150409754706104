import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.scss";

import Results from "./pages/Results";

import axios from "axios";

import * as Survey from "survey-react";
import "survey-react/survey.css";

function App(props) {
  const [complete, setComplete] = useState(false);
  const [data, setData] = useState("");
  const [user, setUser] = useState("");
  const [checked, setChecked] = useState(false);

  let surveyJSON = {
    surveyId: "9f09edf8-531a-491a-8b88-f5c611e018fb",
    showProgressBar: "bottom",
    goNextPageAutomatic: "true",
  };

  Survey.StylesManager.applyTheme("modern");

  function sendDataToServer(survey) {
    survey.sendResult("b6b07b8c-6530-4e36-a8d0-7189d56b92d5");
    setComplete(true);
    setData(survey.data);
    // window.location.href = `mailto:`;
    axios
      .post(`https://dietprofile.drvegan.com/api/surveys`, survey.data)
      .then((res) => {
        setUser(res.data.userId);
      });
  }

  let doAnimantion = true;

  function addAnimation(sender, options) {
    let elem = document.getElementsByClassName("sv-root-modern");
    if (!doAnimantion) {
      elem[0].classList.add("add-animation");
    } else {
      options.allowChanging = false;
      setTimeout(function() {
        doAnimantion = false;
        sender.currentPage = options.newCurrentPage;
        if (sender.isLastPage) {
          sender.goNextPageAutomatic = false;
          sender.allowCompleteSurveyAutomatic = false;
        }
        doAnimantion = true;
      }, 500);
      elem[0].classList.remove("add-animation");
    }
  }

  const handleClick = () => {
    // axios.post(`http://localhost:80/surveys`, data).then(res => {
    //   console.log(res);
    //   console.log(res.data);
    //   setUser(res.data.userId);
    // });
  };

  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  };

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <div id="surveyElement" />
            <Survey.Survey
              json={surveyJSON}
              onComplete={sendDataToServer}
              onCurrentPageChanging={addAnimation}
            />
            {complete && (
              <div className="go_results">
                {/* <p>You're good to go</p>
                <span className="subtitle">
                  Please remember to click save or share on your Diet Profile to
                  save your results. Or try it again – it’s free.
                </span> */}
                {/* <input
                  placeholder="e-mail"
                  type="email"
                  className="custom-input"
                />
                <div className="checkbox-wrap">
                  <div
                    className="custom-checkbox"
                    checked={checked}
                    onClick={handleCheckboxChange}
                    style={
                      checked
                        ? { background: "#00909d", border: "2px solid #00909d" }
                        : { background: "#fff" }
                    }
                  />
                  <span>
                    We use your email to update you about orders, offers and
                    promotions. Tick here to not receive these emails
                  </span>
                </div> */}
                <Link to={`/results/:${user}`} onClick={handleClick}>
                  <button className="btn_results">View my Diet Profile</button>
                </Link>
              </div>
            )}
          </Route>
          <Route
            exact
            path="/results/:id"
            render={(props) => <Results data={data} userId={user} {...props} />}
          ></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

import React from "react";

import PieAnimationChart from "./PieAnimationChart";
import wave from '../assets/wave-png.png';

function CardBody(props) {
  return (
    <div className="results__block">
      <div
        className="results__block--left"
        style={
          props.foodActive === true
            ? { backgroundColor: "#fff", color: "#000" }
            : {
              backgroundColor: "#fff",
              color: "#000"
            }
        }
      >
        <img className="left-wave" src={wave} />

        {/* <div className="left-wave" style={{ background: `url(${wave}) center center no-repeat` }} /> */}
        {/* <svg
          width="32"
          height="170"
          viewBox="0 0 32 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="wave-element-card wave-element-card-black"
        >
          <g filter="url(#filter0_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.0002 160C11.0002 160 11.0002 156.458 11.0002 132.607C11.0002 108.757 21.4199 105.058 21.4199 85.9998C21.4199 66.9415 11.0002 61.6804 11.0002 37.9998C11.0002 14.3191 11.0002 9.99979 11.0002 9.99979H10.0002L10.0002 160H11.0002Z"
              fill="black"
            />
          </g>
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y="0"
              width="31.4197"
              height="170"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
        </svg> */}

        <div className="icon-wrap">
          <div
            style={{
              background: `url(${Object.values(
                props.data[0].image
              )}) center center no-repeat`,
              width: "30px",
              height: "30px",
              marginTop: "20px"
            }}
          />
          <span>{props.data[0].title}</span>
        </div>
      </div>
      <div
        className="results__block--right"
        style={
          props.foodActive === true
            ? { backgroundColor: "#fff", color: "#000" }
            : {
              backgroundColor: "#000",
              color: "#fff"
            }
        }
      >
        {props.data[1].map((data, i) => {
          return (
            <div className="results__icon--card" key={i}>
              <span style={{ position: "absolute", top: "-20%" }}>
                {data.title}
              </span>
              <PieAnimationChart
                progress={data.data.progress}
                text={data.data.text}
                image={data.data}
                opacity={
                  data.data.progress > 71
                    ? "1"
                    : data.data.progress < 71 && data.data.progress > 40
                      ? ".6"
                      : ".3"
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CardBody;

import React, { useState, useEffect } from "react";

import SwitcherBtn from "../components/SwitcherBtn";
import CardBody from "../components/CardBody";
import CardFood from "../components/CardFood";

import dataFood from "../data/dataFood";
import dataBody from "../data/dataBody";

import fishData from "../data/fishData";
import meatData from "../data/meatData";
import eggsData from "../data/eggsData";
import grainsData from "../data/grainsData";
import dairyData from "../data/dairyData";
import fruitvegData from "../data/fruitvegData";
import legumesData from "../data/legumesData";
import shellfishData from "../data/shellfishData";
import meatsubstitutesData from "../data/meatsubstitutesData";
import fatsData from "../data/fatsData";

import PieAnimationChart from "../components/PieAnimationChart";
import share from "../assets/share.png";
import save from "../assets/save.png";
import emailIcon from "../assets/email.png";
import close from "../assets/close.png";
import link from "../assets/link.png";
import axios from "axios";

function Results(props) {
  const [dataBackend, setDataBackend] = useState("");
  const [foodActive, setFoodActive] = useState(true);
  const [popupOpened, setPopupOpened] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  const user = props.userId;

  const { match, location } = props;

  useEffect(() => {
    axios
      .get(
        `https://dietprofile.drvegan.com/api/surveys/${match.params.id.substr(
          1
        )}`
      )
      .then((res) => {
        console.log(res.data.surveyResults);
        setDataBackend(res.data.surveyResults);
      });

    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);

  const handleFoodClick = () => {
    setFoodActive(false);
  };

  const handleBodyClick = () => {
    setFoodActive(true);
  };

  const getArrayNums = (filteredData) =>
    Object.values(filteredData).map((a) => +a);
  const reduceArray = (a) => getArrayNums(a).reduce((a, b) => a + b, 0);
  const filterData = (filterProduct) =>
    Object.keys(dataBackend)
      .filter((key) => filterProduct.includes(key))
      .reduce((obj, key) => {
        obj[key] = dataBackend[key];
        return obj;
      }, {});

  const allowedFish = ["fish", "fish1", "fish2", "fish3", "fish4"];
  const fishProgress =
    reduceArray(filterData(allowedFish)) /
    getArrayNums(filterData(allowedFish)).length;
  const allowedMeat = ["meat", "meat1", "meat2", "meat3"];
  const meatProgress =
    reduceArray(filterData(allowedMeat)) /
    getArrayNums(filterData(allowedMeat)).length;

  const allowedShellfish = ["shellfish", "shellfish1"];
  const shellfishProgress =
    reduceArray(filterData(allowedShellfish)) /
    getArrayNums(filterData(allowedShellfish)).length;

  const allowedGrains = ["grains", "grains1", "grains2"];

  const grainsProgress =
    reduceArray(filterData(allowedGrains)) /
    getArrayNums(filterData(allowedGrains)).length;

  const allowedFats = ["fats", "fats1", "fats2"];

  const fatsProgress =
    reduceArray(filterData(allowedFats)) /
    getArrayNums(filterData(allowedFats)).length;

  const allowedFruitVeg = ["fruit", "vegetables"];

  const fruitvegProgress =
    reduceArray(filterData(allowedFruitVeg)) /
    getArrayNums(filterData(allowedFruitVeg)).length;

  const allowedEggs = ["eggs", "eggs1"];

  const eggsProgress =
    reduceArray(filterData(allowedEggs)) /
    getArrayNums(filterData(allowedEggs)).length;

  const allowedMeatSubstitutes = ["meatsubstitutes", "meatsubstitutes1"];

  const meatSubstitutesProgress =
    reduceArray(filterData(allowedMeatSubstitutes)) /
    getArrayNums(filterData(allowedMeatSubstitutes)).length;

  const allowedLegumes = ["legumes", "legumes1", "legumes2"];

  const legumesProgress =
    reduceArray(filterData(allowedLegumes)) /
    getArrayNums(filterData(allowedLegumes)).length;
  const allowedDairy = ["dairy", "dairy1", "dairy2"];

  const dairyProgress =
    reduceArray(filterData(allowedDairy)) /
    getArrayNums(filterData(allowedDairy)).length;

  fishData.progress = fishProgress;
  meatData.progress = meatProgress;
  eggsData.progress = eggsProgress;
  grainsData.progress = grainsProgress;
  fatsData.progress = fatsProgress;
  dairyData.progress = dairyProgress;
  fruitvegData.progress = fruitvegProgress;
  legumesData.progress = legumesProgress;
  shellfishData.progress = shellfishProgress;
  meatsubstitutesData.progress = meatSubstitutesProgress;
  fatsData.progress = fatsProgress;

  const finalResults = [
    meatsubstitutesData,
    eggsData,
    legumesData,
    fatsData,
    shellfishData,
    meatData,
    grainsData,
    fruitvegData,
    dairyData,
    fishData,
  ];

  if (isNaN(fishProgress) && isNaN(shellfishProgress) && isNaN(meatProgress)) {
    fishData.progress = 0;
    shellfishData.progress = 0;
    meatData.progress = 0;
  }

  if (isNaN(eggsProgress) && isNaN(dairyProgress)) {
    eggsData.progress = 0;
    dairyData.progress = 0;
  }

  finalResults.sort((a, b) => b.progress - a.progress);

  const openPopup = () => {
    setPopupOpened(true);
  };

  const closePopup = () => {
    setPopupOpened(false);
    setCopySuccess("");
  };

  const handleCopyClipboard = (e) => {
    const el = document.createElement("input");
    el.value = window.location.href;
    el.id = "url-input";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    el.remove();
    setCopySuccess("link copied");
  };

  dataFood.sort((a, b) => b[0].data.progress - a[0].data.progress);

  const email = `Congratulations on completing your Diet Profile! 
  %0D%0A%0D%0AYour Diet Profile shows you the most important vitamins and minerals for your body, from your energy, immunity and mental performance to your liver and hair, skin and nails. Lots of foods provide lots of nutrients so your Diet Profile focuses on the three or four best food sources of the essential vitamins and minerals that are more easily absorbed by your body. You can also see ‘Your Diet’s Nutrients’ and the main vitamins and minerals provided by each of the different food groups, and how they support your body.
  %0D%0A%0D%0AView Your Diet Profile
  %0D%0A${window.location.href}
  %0D%0A%0D%0AFind out more about the nutrients that different foods provide in your diet, and how your body benefits, in our comprehensive Nutrition section on our website – click here to find out more 
  https://drvegan.com/pages/foods.  
  %0D%0AOr if you have any questions, take a look at our FAQs https://drvegan.com/pages/faq.
  %0D%0A%0D%0AUpdate your Diet Profile at any time (it’s free) and browse our range of vegan supplements that are kind to your body and kind to the planet at www.drvegan.com.
  %0D%0A%0D%0A%0D%0A%0D%0AThe DR.VEGAN Team
  %0D%0A%0D%0A%0D%0A%0D%0AFollow us:
  %0D%0A%0D%0Ahttps://www.facebook.com/drveganco/
  %0D%0A%0D%0Ahttps://www.instagram.com/drveganco/`;

  return (
    <div className="results">
      {loaded ? (
        <>
          <div className="results__head">
            <h2 className="results__title">
              Your diet <span className="profile">profile </span>
            </h2>
            <p className="results__subtitle">
              Here is a summary of the foods you eat.
            </p>
            <div className="results__icons-wrap">
              {finalResults.map((data, i) => (
                <div className="results__icon" key={i}>
                  <PieAnimationChart
                    progress={data.progress}
                    text={data.text}
                    image={data}
                    // opacity={
                    //   data.progress > 71
                    //     ? "1"
                    //     : data.progress < 71 && data.progress > 30
                    //     ? ".6"
                    //     : ".3"
                    // }
                    uppercase={true}
                  />
                </div>
              ))}
            </div>
            <div className="share-wrap">
              {/* <div className="save" id="save">
                <span>save</span>
                <img src={save} alt="save-icon" />
              </div> */}
              <div className="share" onClick={openPopup}>
                <span>share</span>
                <img src={share} alt="share-icon" />
              </div>
            </div>
            {popupOpened ? (
              <div className="popup">
                <div className="popup_inner">
                  <span className="subtitle">
                    Copy or email a link to your Diet Profile results
                  </span>
                  <div className="share-icons">
                    <a
                      href={`mailto:${dataBackend.email}?subject=My Diet Profile&body=${email}`}
                      target="_parent"
                    >
                      <button className="copy-url">
                        <img src={emailIcon} alt="copy-link" />
                      </button>
                    </a>
                    <button className="copy-url" onClick={handleCopyClipboard}>
                      <img src={link} alt="copy-link" />
                    </button>
                  </div>
                  <p className="copy-url-text">{copySuccess}</p>
                  <button onClick={closePopup} className="close-popup">
                    <img src={close} alt="close-icon" />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="see-more">
              <span className="results__subtitle--mobile">
                See more information
              </span>
              <span className="results__subtitle--desktop">
                scroll to see more
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#000"
              >
                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
              </svg>
            </div>
            <svg
              width="322"
              height="18"
              viewBox="0 0 322 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="wave-element"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.000471515 1.57622C0.000471515 1.57622 0.000469208 1.57622 63.2059 1.57622C126.411 1.57622 118.247 18 159.264 18C200.281 18 199.091 1.57622 260.961 1.57622C322.832 1.57622 321.989 1.57622 321.989 1.57622V0L0.000471515 0L0.000471515 1.57622Z"
                fill="#fff"
              />
            </svg>
          </div>

          <div className="switcher-block-wrap">
            <SwitcherBtn
              handleBodyClick={handleBodyClick}
              handleFoodClick={handleFoodClick}
              foodActive={foodActive}
            />
            {foodActive ? (
              <>
                <p className="switcher-subtitle">
                  Here are the most important nutrients for different parts of
                  your body, the best foods that provide these nutrients, and
                  how much you're eating of these foods. They're not the only
                  sources of each nutrient, but they're considered the best. If
                  your diet excludes some foods, you may be getting some of the
                  nutrients from other foods. You can learn more in our
                  Nutrition section.
                </p>
                {dataBody.map((data, i) => (
                  <CardBody foodActive={foodActive} data={data} key={i} />
                ))}
              </>
            ) : (
              <>
                <p className="switcher-subtitle">
                  Here you see the main nutrients provided by the foods in your
                  diet and their health benefits. You can also see the
                  nutrient's you may be missing out on through the foods you're
                  not eating. Some nutrients provide multiple health benefits.
                  You can learn more in our Nutrition section.
                </p>
                {dataFood.map((data, i) => (
                  <CardFood foodActive={foodActive} data={data} key={i} />
                ))}
              </>
            )}
          </div>
        </>
      ) : (
        <div className="loader">loading</div>
      )}
    </div>
  );
}

export default Results;
